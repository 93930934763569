
import React from "react";
import { useNavigate } from "react-router-dom";

function Logout() {

    const navigate = useNavigate();

    localStorage.removeItem("auth_token");
    setTimeout(() => {
        navigate("/login");
    }, 100);

    return (
        <React.Fragment>
            
        </React.Fragment>
    );

}

export default Logout;