import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/login");
  }, [])
  return (
    <div className="App">
      <header className="App-header">
        <h1>Datrics GmbH Remote Wipe Tool</h1>
      </header>
    </div>
  );
}

export default App;
