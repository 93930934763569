import { Alert, Button, Form } from "react-bootstrap";
import { useEffect, useState } from 'react';
import "../css/LoginPage.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";



function Login() {

  const navigate = useNavigate();

  const [credentails, setCredentials] = useState({
    email: "",
    password: ""
  });
  const [errors, setErrors] = useState({
    emptyEmail: null,
    emptyPassword: null,
    loginFailed: null
  });


  useEffect(() => {
    if(localStorage.getItem("auth_token")) {
      const authToken = localStorage.getItem("auth_token");
      axios.get("/api/v1/me").then(response => {
        navigate("/clients");
      }).catch(e => {

      })
    }
  }, [])

  function handleEmailChange(e) {
    credentails.email = e.target.value;
  }
  function handlePasswordChange(e) {
    credentails.password = e.target.value;
  }

  function apiUserLogin() {

    /* check weather password or email are empty */
    errors.emptyEmail = credentails.email === "";
    errors.emptyPassword = credentails.password === "";
    if(errors.emptyEmail || errors.emptyPassword) {
      setErrors({
        ...errors,
        emptyEmail: errors.emptyEmail,
        emptyPassword: errors.emptyPassword
      })
      return;
    }

    /* make api request */
    const requestData = {
      username: credentails.email,
      password: credentails.password
    }
    axios.post("/api/v1/auth", requestData).then(response => {
      const jwt = response.data.jwt;
      localStorage.setItem("auth_token", jwt);
      navigate("/clients");
    }).catch(error => {
      setErrors({
        ...errors,
        loginFailed: true
      })
    })
  }

  return (
      <div class="flexWrapper d-flex align-items-center justify-content-center">
      <Form className="loginForm">
        <div class="loginFormHeader">
        <img src="https://datrics.de/wp-content/uploads/2021/03/Datrics_logo.png" />
        <hr />
        {errors.loginFailed && <Alert variant="danger">Invalid Credentials!</Alert>}
        </div>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" placeholder="Type your email" onChange={handleEmailChange} isInvalid ={errors.emptyEmail || errors.loginFailed} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" placeholder="Type your password" onChange={handlePasswordChange} isInvalid={errors.emptyPassword || errors.loginFailed} />
        </Form.Group>
        <Button variant="primary" className="loginButton" onClick={apiUserLogin}>
          Login
        </Button>
      </Form>
      </div>
  );
}

export default Login;
