import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import "../css/ClientManagement.css"

import { Table, Button, Form, Badge, Modal, Alert } from "react-bootstrap";
import axios from "axios";

import "../css/UserManagement.css";

function UserManagement() {

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [reloadCounter, setReloadCounter] = useState(0);
    const [users, setUsers] = useState([]);
    const [showCreateUserModal, setShowCreateUserModal] = useState(false);
    const [newUserError, setNewUserError] = useState(null);
    
    let newUserEmail;
    let newUserPassword;

    useEffect(() => {
        axios.get("/api/v1/users").then(response => {
            const u = response.data;
            setUsers(u);
        }).catch(e => {

        });

    }, [reloadCounter]);


    function deleteUser() {

        axios.delete("/api/v1/users/" + userToDelete?._id).then(r => {
            setShowConfirmModal(false);
            setReloadCounter(reloadCounter + 1);
        }).catch(e => {

        })

    }

    function createUser() {

        
        setNewUserError(null);

        if(!newUserEmail || newUserEmail.length < 4) {
            setNewUserError("enter a valid email");
            return;
        }

        if(!newUserPassword || newUserPassword < 6) {
            setNewUserError("the password needs at least 6 chars");
            return;
        }

        axios.post("/api/v1/users", {username: newUserEmail, password: newUserPassword}).then(result => {
            setShowCreateUserModal(false);
            setReloadCounter(reloadCounter + 1);

        }).catch(error => {
            setNewUserError("Error while creation. Please check credentials.");
        })
        


    }


    function ConfirmModal(props) {
        return (
          <Modal show={showConfirmModal} onHide={() => {setShowConfirmModal(false)}}>

              <Modal.Header closeButton>
                <Modal.Title>Delete <b>{userToDelete?.username}</b></Modal.Title>
              </Modal.Header>
      
              <Modal.Body>
                <p>Are you sure to delete {userToDelete?.username}</p>
              </Modal.Body>
      
              <Modal.Footer>
                <Button variant="danger" onClick={deleteUser}>Delete</Button>
              </Modal.Footer>

          </Modal>
        );
      }
    
    function changeNewUserEmail(event) {
        newUserEmail = event.target.value;
    }

    function changeNewUserPassword(event) {
        newUserPassword = event.target.value;
    }

    function CreateUserModal() {
        return (
            <Modal show={showCreateUserModal} onHide={() => {setShowCreateUserModal(false)}}>

              <Modal.Header closeButton>
                <Modal.Title>Create new User</Modal.Title>
              </Modal.Header>
      
              <Modal.Body>
                
                    {newUserError && <Alert variant="danger">{newUserError}</Alert>}
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" onChange={changeNewUserEmail} placeholder="Enter email" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" onChange={changeNewUserPassword} placeholder="Password" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" disabled={true} label="Send invitation mail" />
                        </Form.Group>
                    </Form>
                
                </Modal.Body>
            <Modal.Footer>
            <Button variant="success" onClick={createUser}>Add User</Button>
            </Modal.Footer>

          </Modal>
        )
    }

    return (
        <React.Fragment>
                <NavBar></NavBar>
                <div class="flexWrapper">
                {userToDelete && <ConfirmModal />}
                <CreateUserModal />
                   <center> <h1>User Management</h1></center>


                    <Button className="addUserBtn" variant="info" onClick={() => {setShowCreateUserModal(true);}}>Add new User</Button>

                    <Table striped bordered hover className="table-dark">
                        <thead>
                        <tr>
                            <th>id</th>
                            <th>username</th>
                            <th>Last Login</th>
                            <th>Delete User</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            users.map(user => {
                                return (
                                
                                    <tr class="clientEntry notLocked">
                                    <td>{user._id}</td>
                                    <td><b>{user.username}</b></td>
                                    <td>{new Date(user.lastLogin).toLocaleString()}</td>
                                    <td><Button variant="danger" onClick={() => {setUserToDelete(user);setShowConfirmModal(true);}}>Delete {user.username}</Button></td>
                                </tr> 

                                );
                            })
                        }
                        
                        </tbody>
                    </Table> 

                </div>
        </React.Fragment>
    );

}

export default UserManagement;