import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import "../css/ClientManagement.css"

import { Table, Button, Form, Badge, Modal } from "react-bootstrap";
import axios from "axios";

function ClientManagement() {

    const [clients, setClients] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [selectedClient, setSelectedClient] = useState(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [reload, setReload] = useState(0);

    useEffect(() => {

        axios.get("/api/v1/clients").then(response => {
            const c = response.data;
            setClients(c);
        }).catch(e => {

        })

    }, [reload]);

    function toggleLockClient() {
        setShowConfirmModal(false);
        axios.patch("/api/v1/clients/" + selectedClient.uuid + "/locked", {status: !selectedClient.locked}).finally(() => {
            setReload(reload + 1);
        })
    }

    function ConfirmModal(props) {
        return (
          <Modal show={showConfirmModal} onHide={() => {setShowConfirmModal(false)}}>

              <Modal.Header closeButton>
                <Modal.Title>Confirm <b>{props.lock ? "Lock" : "Unlock"}</b></Modal.Title>
              </Modal.Header>
      
              <Modal.Body>
                <p>Are you sure to {props.lock ? "Lock" : "Unlock"} {props.hostname}</p>
              </Modal.Body>
      
              <Modal.Footer>
                <Button variant={props.lock ? "danger" : "primary"} onClick={toggleLockClient}>{props.lock ? "Lock" : "Unlock"}</Button>
              </Modal.Footer>

          </Modal>
        );
      }
    
    function changeSearchText(event) {
        setSearchText(event.target.value);
    }

    return (
        <React.Fragment>
                <NavBar></NavBar>
                <div class="flexWrapper">
                {selectedClient && <ConfirmModal lock={!selectedClient.locked} hostname={selectedClient.hostname} />}
                   <center> <h1>Client Management</h1></center>

                    <Form.Control
                        type="text"
                        placeholder="search..."
                        className="searchField"
                        onChange={changeSearchText}
                        dark
                    />

                    <Table striped bordered hover className="table-dark">
                        <thead>
                        <tr>
                            <th>Hostname</th>
                            <th>UUID</th>
                            <th>Last Check</th>
                            <th>Locked</th>
                            <th>Lock Client</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            clients.filter(client => searchText == "" || searchText == undefined ||  (client.hostname.toLowerCase().includes(searchText.toLowerCase())) || (client.uuid.toLowerCase().includes(searchText.toLowerCase()))).map(client => {
                                return (
                                
                                    <tr class="clientEntry notLocked">
                                    <td><b>{client.hostname}</b></td>
                                    <td>{client.uuid}</td>
                                    <td>{new Date(client.lastCheck).toLocaleString()}</td>
                                    <td><Badge bg={client.locked ? client.confirmed ? "danger" : "warning" : "success"}>{client.locked ? client.confirmed ? "LOCKED" : "PENDING" : "UNLOCKED"}</Badge></td>
                                    <td><Button variant={!client.locked ? "danger" : "info"} onClick={() => {setSelectedClient(client);setShowConfirmModal(true);}}>{client.locked ? "unlock" : "lock"} {client.hostname}</Button></td>
                                </tr> 

                                );
                            })
                        }
                        
                        </tbody>
                    </Table> 

                </div>
        </React.Fragment>
    );
};

export default ClientManagement;