import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './Router';
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

import "./css/index.css";
import axios from "axios";

axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth_token") ?? "";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>
);