import {
  createBrowserRouter,
  createHashRouter,
  RouterProvider,
} from "react-router-dom";

import App from './App';
import Login from './pages/Login';
import ClientManagement from "./pages/ClientManagement";
import ErrorPage from './pages/ErrorPage';
import UserManagement from "./pages/UserManagement";
import Logout from "./pages/Logout";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/clients", 
    element: <ClientManagement />,
  },
  {
    path: "/users", 
    element: <UserManagement />,
  },
  {
    path: "/Logout",
    element: <Logout />,
  }
]);

function Router() {
    return (
    <RouterProvider router={router} />
    );
}

export default Router;